import Link from 'next/link';
import NavBar from '../components/nav_bar'
import { useTranslations } from 'next-intl';

export default function Custom404() {
  const t = useTranslations('home');

  return (
    <div className="relative">
      {/*-------------- NavBar Section ------------------*/}
      <NavBar pageName={''} />

      <div className="bg-gray-800 w-full h-[92vh] relative overflow-hidden">
        <div className="absolute z-20 w-full h-2/4 flex flex-col justify-evenly items-center">
          <span className="text-4xl font-bold text-center text-white">
            {t("Got Lost,")}
            <Link href="/">
              <span className="font-thin underline underline-offset-2 px-2">{t("Go Home")}</span>
            </Link>
          </span>
          <p className="font-extrabold text-white text-8xl animate-bounce">
            404
          </p>
        </div>
        <div className="z-10 relative w-full h-full mx-auto">
          <img src="/404.png" className="absolute left-0 right-0 m-auto bottom-0 h-[50vh]" />
        </div>
      </div>
    </div>
  )
}


export async function getStaticProps({ locale }) {

  return {
    props: {
      messages: require(`../locales/${locale}.json`),

    },
    revalidate: 10
  };
}


